import * as React from "react";
import { useEffect, useState } from "react";
import {
  CommentDto,
  RequestDto,
  RequestStatus,
  Roles,
  UserDto,
} from "../shared/sharedModel";
import "./RequestForm.scss";
import { Button } from "@progress/kendo-react-buttons";
import { DatePicker } from "@progress/kendo-react-dateinputs";
import {
  ComboBox,
  ComboBoxChangeEvent,
  DropDownList,
} from "@progress/kendo-react-dropdowns";
import {
  Checkbox,
  Input,
  InputChangeEvent,
  NumericTextBox,
  NumericTextBoxChangeEvent,
  Switch,
  TextArea,
} from "@progress/kendo-react-inputs";
import { useNavigate } from "react-router-dom";
import {
  GetAllAnswers,
  PushRequests,
  GetUserWithOkta,
  GetRequestById,
  GetTeExperts,
  GetAttachments,
  GetTeSpock,
  GetValidators,
  GetCommentsByRequestId,
  PostComment,
  RemoveComment,
  RemoveOwnAttachment,
  PostRequest,
} from "../shared/apiService";
import { useMutation, useQuery } from "react-query";
import {
  Form,
  Field,
  FormElement,
  FieldRenderProps,
} from "@progress/kendo-react-form";
import { Hint, Label } from "@progress/kendo-react-labels";
import { useLocation, useParams } from "react-router";
import { toast } from "react-toastify";
import { Dialog } from "@progress/kendo-react-dialogs";
import { Rejection } from "./Rejection";
import { saveAs } from "file-saver";
import { Loader } from "@progress/kendo-react-indicators";
import { NumberFormatOptions } from "@progress/kendo-react-intl";
import ChatComponent from "../ChatComponent/ChatComponent";
import { useTranslation } from "react-i18next";
import { Upload } from "@progress/kendo-react-upload";
import RequestStepper from "./RequestStepper";
import { checkForVacation, displayVacationInfo } from "./vacationModeHandlers";
import { priorityLevels } from "../shared/priorityLevels";
import SubjectDialog from "./SubjectDialog";

const RequestForm = () => {
  const { id } = useParams();

  const [futureStatus, setFutureStatus] = useState<{
    id: number;
    value: string;
  }>();

  const [isInEdit, setIsInEdit] = useState<boolean>(false);

  const [requestBody, setRequestBody] = useState<RequestDto | undefined>();
  const [answersList, setAnswersList] = useState([]);
  const [comments, setComments] = useState<CommentDto[]>([]);
  const [attachments, setAttachments] = useState<any>([]);
  const [subSpoc, setSubSpoc] = useState<any>();
  const [imputationNumber, setImputationNumber] = useState<string>("");

  const [isDialogVisible, setIsDialogVisible] = useState(false);
  const [isSubjectDialogVisible, setIsSubjectDialogVisible] = useState(false);
  const [estimation, setEstimation] = useState<number>(
    requestBody?.estimationHours!
  );
  const [cost, setCost] = useState<number>(requestBody?.hourlyRate!);
  const [deadlineEstimation, setDeadlineEstimation] = useState<Date>(
    new Date()
  );

  const user = useQuery<UserDto, Error>(["userOkta"], () => GetUserWithOkta());

  let navigate = useNavigate();
  const location: any = useLocation();
  const { t } = useTranslation();

  useEffect(() => {
    if (location?.state?.status) {
      location?.state?.status.toString() === "true" &&
        toast.success("Request created");
      location?.state?.status.toString() === "false" && toast.error("Error");
    }
    location?.state?.message && toast.warn(location?.state?.message);
  }, []);

  const attachmentsComponent = requestBody?.attachments.map(
    (attachment: any) => (
      <div key={attachment?.originalFileName} className="attachment-item">
        <div>
          <Label>{attachment?.originalFileName}</Label>
        </div>
        <div>
          <Button
            type="button"
            onClick={() =>
              GetAttachments(attachment?.id!).then((res) =>
                saveAs(res, attachment?.originalFileName)
              )
            }
          >
            Download
          </Button>
          {attachment.createdBy &&
            user.data?.id === attachment.createdBy.toLowerCase() && (
              <Button
                type="button"
                onClick={() => handleDeleteAttachment(attachment)}
              >
                X
              </Button>
            )}
        </div>
      </div>
    )
  );

  const commentsQuery = useQuery<CommentDto[], Error>(
    "comments",
    () => {
      if (requestBody !== null && requestBody !== undefined) {
        return GetCommentsByRequestId(requestBody?.id!);
      } else {
        setTimeout(() => {
          commentsQuery.refetch();
        }, 500);
        return [];
      }
    },
    {
      onSuccess: (data) => setComments(data),
    }
  );

  const validators = useQuery<any, Error>(
    ["validators", requestBody?.companyId!],
    () => GetValidators(requestBody?.companyId!)
  );

  useQuery("all answers", GetAllAnswers, {
    onSuccess: (data) => setAnswersList(data),
  });

  const teExpertList = useQuery("get Te Experts", GetTeExperts, {
    refetchOnWindowFocus: false,
  });

  const teSpoc = useQuery("get TE SPOC", GetTeSpock, {
    refetchOnWindowFocus: false,
  });

  const updateRequest = useMutation(PushRequests, {
    onMutate: () => {},
    onSuccess: () => {
      getRequestById.refetch();
      toast.success("Request updated");
    },
    onError: () => {
      toast.error(`Request couldn't be updated`);
    },
  });

  const deleteAttachment = useMutation(RemoveOwnAttachment, {
    onSuccess: () => {
      toast.success("Attachment removed");
      window.location.reload();
    },
    onError: () => {
      toast.error(`Attachment couldn't be removed`);
    },
  });

  const updateComments = useMutation(PostComment, {
    onSuccess: () => {
      commentsQuery.refetch();
      toast.success("Comment posted");
    },
    onError: () => {
      toast.error("Error while posting the comment");
    },
  });

  const updateRequestUser = useMutation(PostRequest, {
    onSuccess: () => {
      getRequestById.refetch();
      toast.success("User updated");
    },
    onError: () => {
      toast.error("Request couldn't be updated");
    },
  });

  const deleteComment = useMutation(RemoveComment, {
    onSuccess: () => {
      commentsQuery.refetch();
      toast.success("Comment removed");
    },
    onError: () => {
      toast.error("Error while removing the comment");
    },
  });

  const getRequestById = useQuery<any, Error>(
    "get request by id",
    () => id && GetRequestById(+id),
    {
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        setRequestBody(data);
        setEstimation(data.estimationHours);
        setImputationNumber(data.imputationNumber);
        handleCost(data);
      },
      onError: () => navigate("/"),
    }
  );
  /*
  Workflow handler
  Default: read only mode
   */
  useEffect(() => {
    requestBody?.proposedDeadline &&
      setDeadlineEstimation(() => new Date(requestBody?.proposedDeadline!));
    if (user.data?.engieUserRole === Roles.Admin) {
      setIsInEdit(true);
    } else if (
      requestBody &&
      requestBody?.setStatusDto?.currentStatus === RequestStatus.Submitted &&
      user.data?.engieUserRole === Roles.SubjectSpoc
    ) {
      setIsInEdit(true);
    } else if (
      requestBody &&
      (requestBody?.setStatusDto?.currentStatus === RequestStatus.Reviewing ||
        requestBody?.setStatusDto?.currentStatus ===
          RequestStatus.RejectedByClient) &&
      user?.data?.engieUserRole === Roles.TeSpoc &&
      user?.data?.id == requestBody?.teSpocApplicationUser?.id
    ) {
      setIsInEdit(true);
    } else if (
      requestBody?.setStatusDto?.currentStatus === RequestStatus.Estimated &&
      user.data?.engieUserRole === Roles.Validator &&
      requestBody.validatorApplicationUser?.id === user.data.id
    ) {
      setIsInEdit(true);
    } else if (
      requestBody?.setStatusDto?.currentStatus === RequestStatus.Accepted &&
      user.data?.engieUserRole === Roles.TeExpert &&
      requestBody.teExpertApplicationUser?.id === user.data.id
    ) {
      setIsInEdit(true);
    } else if (
      requestBody?.setStatusDto?.currentStatus === RequestStatus.InProgress &&
      user.data?.engieUserRole === Roles.TeExpert &&
      requestBody.teExpertApplicationUser?.id === user.data.id
    ) {
      setIsInEdit(true);
    } else if (
      requestBody?.setStatusDto?.currentStatus ===
        RequestStatus.AwaitingFeedback &&
      user.data?.engieUserRole === Roles.TeExpert &&
      requestBody.teExpertApplicationUser?.id === user.data.id
    ) {
      setIsInEdit(true);
    } else if (
      requestBody?.setStatusDto?.currentStatus === RequestStatus.Revision &&
      user.data?.engieUserRole === Roles.TeSpoc &&
      requestBody.teSpocApplicationUser?.id === user.data.id
    ) {
      setIsInEdit(true);
    } else if (
      requestBody?.setStatusDto?.currentStatus === RequestStatus.Finished &&
      user.data?.engieUserRole === Roles.Requester &&
      requestBody.requesterApplicationUserId === user.data.id
    ) {
      setIsInEdit(true);
    } else {
      setIsInEdit(false);
    }
    setSubSpoc(
      requestBody?.company?.companySubjects?.find(
        (element: any) => element?.subjectId === requestBody?.subject?.id
      )?.subjectSpocApplicationUser!
    );
  }, [futureStatus, requestBody]);

  const submitRequest = (event?: any) => {
    const request: RequestDto = {
      id: +id!,
      requestId: requestBody?.requestId!,
      title: requestBody?.title!,
      description: requestBody?.description!,
      requesterApplicationUserId: requestBody?.requesterApplicationUserId!,
      validatorApplicationUserId: requestBody?.validatorApplicationUserId!,
      teSpocApplicationUserId: requestBody?.teSpocApplicationUser?.id,
      subjectId: requestBody?.subjectId!,
      companyId: requestBody?.companyId!,
      deadline: requestBody?.deadline!,
      priorityLevel: requestBody?.priorityLevel!,
      wdNumber: requestBody?.wdNumber,
      projectNumber: requestBody?.projectNumber,
      selectedTypesOfAnswer: requestBody?.selectedTypesOfAnswer,
      attachments: attachments.map((attachment: any) => {
        return {
          originalFileName: attachment.originalFileName,
          fileNameWithTimeStampPrefix: attachment.fileNameWithTimeStampPrefix,
          guid: attachment.guid,
          id: attachment.id,
        };
      }),
      setStatusDto: {
        requestId: requestBody?.requestId!,
        currentStatus: requestBody?.setStatusDto?.currentStatus!,
        futureStatus: event?.futureStatus
          ? event.futureStatus
          : calculateFutureStatus(),
      },
      rejectionReason: event?.rejectionReason,
      estimationHours: estimation,
      hourlyRate: cost,
      estimationCost: estimation * cost,
      proposedDeadline: deadlineEstimation.toISOString(),
      estimationComment: event?.estimationComment,
      teExpertApplicationUserId: requestBody?.teExpertApplicationUser?.id,
      imputationNumber: imputationNumber,
      budgetOwner: requestBody?.budgetOwner!,
    };
    if (handleValidation(request)) updateRequest.mutate(request);
  };

  const submitComment = (commentBody: string) => {
    updateComments.mutate({
      content: commentBody,
      requestEntityId: requestBody!.id!,
    });
  };

  const removeComment = (commentId: string) => {
    deleteComment.mutate(commentId);
  };

  const toggleDialog = () => {
    setIsDialogVisible(!isDialogVisible);
  };

  const handleEstimationChange = (event: NumericTextBoxChangeEvent) => {
    if (event.value) setEstimation(event.value);
  };

  const handleValidation = (request: RequestDto) => {
    if (requestBody?.setStatusDto?.currentStatus === RequestStatus.Submitted) {
      if (!requestBody.teSpocApplicationUser) {
        toast.error("You have to specify TE Spoc");
        return false;
      }
      const subjectSpoc = requestBody.company?.companySubjects.find(
        (cd) => cd.subjectId === requestBody.subjectId
      )?.subjectSpocApplicationUserId;
      if (
        user.data?.engieUserRole !== Roles.Admin &&
        user?.data?.id !== subjectSpoc
      ) {
        toast.error(`You're the wrong subject spoc!`);
        return false;
      }
    }
    if (requestBody?.setStatusDto?.currentStatus === RequestStatus.Reviewing) {
      if (!estimation) {
        toast.error("You have to specify Estimation");
        return false;
      }
      if (!requestBody?.teExpertApplicationUser) {
        toast.error("You have to specify TE Expert");
        return false;
      }
    }
    return true;
  };

  const calculateFutureStatus = (): number => {
    let calculatedStatus;
    if (
      requestBody?.setStatusDto?.currentStatus! ===
        RequestStatus.RejectedByClient ||
      requestBody?.setStatusDto?.currentStatus! === RequestStatus.Revision ||
      requestBody?.setStatusDto?.currentStatus! ===
        RequestStatus.RejectedByClient
    ) {
      calculatedStatus = RequestStatus.Estimated;
    } else {
      calculatedStatus = requestBody?.setStatusDto?.currentStatus! + 1;
    }
    return calculatedStatus;
  };

  const handleCost = (request: RequestDto) => {
    switch (request.priorityLevel) {
      case 1:
        setCost(request.company?.hourlyRateForPriority1!);
        break;
      case 2:
        setCost(request.company?.hourlyRateForPriority2!);
        break;
      case 3:
        setCost(request.company?.hourlyRateForPriority3!);
        break;
    }
  };

  const handleValidatorChange = (event: ComboBoxChangeEvent) => {
    if (requestBody) {
      setRequestBody((prevState: any) => ({
        ...prevState,
        validatorApplicationUser: event.value,
      }));
    }
    if (event.value) checkForVacation(event);
  };

  const handleTeSpocChange = (event: ComboBoxChangeEvent) => {
    if (requestBody) {
      setRequestBody((prevState: any) => ({
        ...prevState,
        teSpocApplicationUser: event.value,
      }));
    }
    if (event.value) checkForVacation(event);
  };

  const handleTeExpertChange = (event: ComboBoxChangeEvent) => {
    if (requestBody) {
      setRequestBody((prevState: any) => ({
        ...prevState,
        teExpertApplicationUser: event.value,
      }));
    }
    if (event.value) checkForVacation(event);
  };

  const handleImputationNumberChange = (event: InputChangeEvent) => {
    if (requestBody) {
      setImputationNumber(event.value);
    }
  };

  const updateUser = () => {
    const request: RequestDto = {
      id: +id!,
      requestId: requestBody?.requestId!,
      title: requestBody?.title!,
      description: requestBody?.description!,
      requesterApplicationUserId: requestBody?.requesterApplicationUserId!,
      validatorApplicationUserId: requestBody?.validatorApplicationUser?.id!,
      teSpocApplicationUserId: requestBody?.teSpocApplicationUser?.id!,
      subjectId: requestBody?.subjectId!,
      companyId: requestBody?.companyId!,
      deadline: requestBody?.deadline!,
      priorityLevel: requestBody?.priorityLevel!,
      wdNumber: requestBody?.wdNumber,
      projectNumber: requestBody?.projectNumber,
      imputationNumber: requestBody?.imputationNumber,
      selectedTypesOfAnswer: requestBody?.selectedTypesOfAnswer,
      attachments: attachments.map((attachment: any) => {
        return {
          originalFileName: attachment.originalFileName,
          fileNameWithTimeStampPrefix: attachment.fileNameWithTimeStampPrefix,
          guid: attachment.guid,
          id: attachment.id,
        };
      }),
      setStatusDto: {
        requestId: requestBody?.requestId!,
        currentStatus: requestBody?.setStatusDto?.currentStatus!,
        futureStatus: requestBody?.setStatusDto?.futureStatus!,
      },
      rejectionReason: requestBody?.rejectionReason,
      estimationHours: estimation,
      estimationCost: cost! * estimation,
      hourlyRate: cost!,
      proposedDeadline: requestBody?.proposedDeadline,
      estimationComment: requestBody?.estimationComment,
      teExpertApplicationUserId: requestBody?.teExpertApplicationUser?.id!,
    };
    updateRequestUser.mutate(request);
  };

  const toFinished = () => {
    const event = {
      imputationNumber: imputationNumber,
      futureStatus: RequestStatus.Finished,
    };
    submitRequest(event);
  };

  const toInProgress = () => {
    const event = {
      imputationNumber: imputationNumber,
      futureStatus: RequestStatus.InProgress,
    };
    submitRequest(event);
    };

  const toRevision = () => {
      const event = {
          futureStatus: RequestStatus.Revision,
        };
        submitRequest(event);
    };

  const euroFormat: NumberFormatOptions = {
    style: "currency",
    currency: "EUR",
    currencyDisplay: "symbol",
  };

  const addAttachmentsHandler = (event: any) => {
    event.response?.response?.map((element: any) => {
      setAttachments((prevAttachments: any) => [
        ...prevAttachments,
        {
          originalFileName: element.originalFileName,
          fileNameWithTimeStampPrefix: element.fileNameWithTimeStampPrefix,
          guid: element.guid,
          id: element.id,
          removeUid: event.affectedFiles[0].uid,
        },
      ]);
    });
  };

  const removeAttachmentHandler = (event: any) => {
    const attachmentState = attachments.filter(
      (attachment: any) => event.affectedFiles[0].uid !== attachment.removeUid
    );
    setAttachments(attachmentState);
  };

  const handleDeleteAttachment = (attachment: any) => {
    deleteAttachment.mutate(attachment.id);
  };
  const IdInput = ({
    validationMessage,
    visited,
    value,
    ...others
  }: FieldRenderProps) => (
    <Input
      value={requestBody?.requestId}
      required={true}
      readOnly={true}
      {...others}
    />
  );
  const StatusComboBox = ({
    validationMessage,
    visited,
    onChange,
    value,
    ...others
  }: FieldRenderProps) => (
    <Input
      defaultValue={t(RequestStatus[requestBody?.setStatusDto?.currentStatus!])}
      readOnly={true}
      {...others}
    />
  );
  const RejectionReasonTextArea = ({
    validationMessage,
    visited,
    value,
    ...others
  }: FieldRenderProps) => (
    <TextArea
      defaultValue={requestBody?.rejectionReason}
      required={true}
      readOnly={futureStatus?.id !== 2 || isInEdit}
      {...others}
    />
  );
  const TitleInput = ({
    validationMessage,
    visited,
    ...others
  }: FieldRenderProps) => (
    <Input
      defaultValue={requestBody?.title}
      required={true}
      readOnly={true}
      {...others}
    />
  );
  const DescriptionTextArea = ({
    validationMessage,
    visited,
    ...others
  }: FieldRenderProps) => (
    <TextArea
      defaultValue={requestBody?.description}
      readOnly={true}
      required={true}
      {...others}
    />
  );
  const RequesterInput = ({
    validationMessage,
    visited,
    value,
    ...others
  }: FieldRenderProps) => (
    <Input
      required={true}
      readOnly={true}
      defaultValue={requestBody?.requesterApplicationUser?.fullName}
      {...others}
    />
  );
  const DepartmentComboBox = ({
    validationMessage,
    visited,
    onChange,
    value,
    ...others
  }: FieldRenderProps) => (
    <Input readOnly={true} value={requestBody?.department?.name} {...others} />
  );
  const RequestInTheNameOfInput = ({
    validationMessage,
    visited,
    value,
    ...others
  }: FieldRenderProps) => (
    <Input
      {...others}
      readOnly={true}
      value={requestBody?.requestInTheNameOf}
    />
  );
  const ValidatorComboBox = ({
    validationMessage,
    visited,
    onChange,
    ...others
  }: FieldRenderProps) => (
    <ComboBox
      required={true}
      disabled={
        !(
          user?.data?.engieUserRole === Roles.Admin ||
          (user?.data?.isOnVacation &&
            requestBody?.validatorApplicationUser?.id === user?.data?.id)
        )
      }
      onChange={(event) => handleValidatorChange(event)}
      defaultValue={requestBody?.validatorApplicationUser}
      data={validators.data}
      textField="fullName"
      dataItemKey="id"
      {...others}
    />
  );
  const BudgetOwnerInput = ({
    validationMessage,
    value,
    ...others
  }: FieldRenderProps) => (
    <Input readOnly={true} value={requestBody?.budgetOwner} {...others} />
  );
  const TeSPOCComboBox = ({
    validationMessage,
    visited,
    onChange,
    ...others
  }: FieldRenderProps) => (
    <ComboBox
      disabled={
        !(
          (isInEdit &&
            requestBody?.setStatusDto?.currentStatus ===
              RequestStatus.Submitted) ||
          user?.data?.engieUserRole === Roles.Admin ||
          (user?.data?.isOnVacation &&
            requestBody?.teSpocApplicationUser?.id === user?.data?.id)
        )
      }
      required={true}
      data={teSpoc.data}
      defaultValue={requestBody?.teSpocApplicationUser}
      onChange={(event) => handleTeSpocChange(event)}
      textField="fullName"
      dataItemKey="id"
      {...others}
    />
  );
  const TeExpertComboBox = ({
    validationMessage,
    visited,
    onChange,
    ...others
  }: FieldRenderProps) => (
    <ComboBox
      disabled={
        !(
          (isInEdit &&
            requestBody?.setStatusDto?.currentStatus ===
              RequestStatus.Reviewing) ||
          user?.data?.engieUserRole === Roles.Admin ||
          (user?.data?.isOnVacation &&
            requestBody?.teExpertApplicationUser?.id === user?.data?.id)
        )
      }
      required={true}
      data={teExpertList.data}
      defaultValue={requestBody?.teExpertApplicationUser}
      onChange={(event) => {
        handleTeExpertChange(event);
      }}
      textField="fullName"
      dataItemKey="id"
      {...others}
    />
  );

  const SubSpocInput = ({
    validationMessage,
    visited,
    value,
    ...others
  }: FieldRenderProps) => (
    <Input
      required={true}
      readOnly={true}
      defaultValue={subSpoc?.fullName}
      {...others}
    />
  );

  const SubjectInput = ({
    validationMessage,
    visited,
    value,
    ...others
  }: FieldRenderProps) => (
    <Input
      required={true}
      readOnly={true}
      defaultValue={requestBody?.subject?.name}
      {...others}
    />
  );
  const WdNumberInput = ({
    validationMessage,
    visited,
    ...others
  }: FieldRenderProps) => (
    <Input defaultValue={requestBody?.wdNumber} readOnly={true} {...others} />
  );
  const ProjectNumberInput = ({
    validationMessage,
    visited,
    ...others
  }: FieldRenderProps) => (
    <Input
      defaultValue={requestBody?.projectNumber}
      readOnly={true}
      {...others}
    />
  );
  const ReferenceDropdownList = ({
    validationMessage,
    visited,
    value,
    onChange,
    ...others
  }: FieldRenderProps) => (
    <DropDownList
      disabled={true}
      textField="name"
      value={requestBody?.reference}
      {...others}
    />
  );
  const ReferenceInput = ({
    validationMessage,
    visited,
    value,
    ...others
  }: FieldRenderProps) => (
    <Input disabled={true} value={requestBody?.referenceNumber} {...others} />
  );
  const ReferenceCheckbox = ({
    validationMessage,
    ...others
  }: FieldRenderProps) => (
    <Checkbox checked={requestBody?.referenceCheckbox} {...others} />
  );
  const SeismicQuestionsSwitch = ({
    validationMessage,
    value,
    name,
    ...others
  }: FieldRenderProps) => (
    <Switch name={name} checked={requestBody?.isSeismic} {...others} />
  );
  const DeadlineDatePicker = ({
    validationMessage,
    visited,
    value,
    ...others
  }: FieldRenderProps) => (
    <DatePicker
      disabled={true}
      value={new Date(requestBody?.deadline!)}
      format="dd-MM-yyyy"
      {...others}
    />
  );
  const DeadlineEstimationDatePicker = ({
    validationMessage,
    visited,
    onChange,
    value,
    ...others
  }: FieldRenderProps) => (
    <DatePicker
      disabled={
        !(
          isInEdit &&
          (requestBody?.setStatusDto?.currentStatus ===
            RequestStatus.Reviewing ||
            requestBody?.setStatusDto?.currentStatus ===
              RequestStatus.RejectedByClient ||
            requestBody?.setStatusDto?.currentStatus === RequestStatus.Revision)
        )
      }
      value={deadlineEstimation}
      min={new Date()}
      onChange={(event) => setDeadlineEstimation(event?.value!)}
      required={true}
      format="dd-MM-yyyy"
      {...others}
    />
  );
  const PriorityDropDownList = ({
    validationMessage,
    visited,
    ...others
  }: FieldRenderProps) => (
    <DropDownList
      disabled={true}
      defaultValue={priorityLevels.find(
        (element) => element.id === requestBody?.priorityLevel
      )}
      required={true}
      data={priorityLevels}
      textField="value"
      {...others}
    />
  );
  const AnswerSwitch = ({
    validationMessage,
    visited,
    valid,
    name,
    ...others
  }: FieldRenderProps) => (
    <Switch
      disabled={true}
      checked={requestBody?.selectedTypesOfAnswer.some(
        (element: any) => element.typeOfAnswerDictionaryId === +name
      )}
      {...others}
    />
  );

  const EstimationComment = ({
    validationMessage,
    visited,
    ...others
  }: FieldRenderProps) => (
    <TextArea
      defaultValue={requestBody?.estimationComment}
      readOnly={
        !(
          isInEdit &&
          (requestBody?.setStatusDto?.currentStatus ===
            RequestStatus.Reviewing ||
            requestBody?.setStatusDto?.currentStatus ===
              RequestStatus.RejectedByClient ||
            requestBody?.setStatusDto?.currentStatus === RequestStatus.Revision)
        )
      }
      {...others}
    />
  );

  return (
    <React.Fragment>
      {isDialogVisible && (
        <Dialog>
          <Rejection
            requestBody={requestBody}
            toggleDialog={toggleDialog}
            getRequestById={getRequestById}
          />
        </Dialog>
      )}
      {isSubjectDialogVisible && (
        <Dialog>
          <SubjectDialog
            requestBody={requestBody}
            setIsSubjectDialogVisible={setIsSubjectDialogVisible}
            getRequestById={getRequestById}
          />
        </Dialog>
      )}
      <div className="requestFrom-container">
        {requestBody?.setStatusDto && (
          <RequestStepper requestBody={requestBody} />
        )}

        <Form
          onSubmitClick={(e) => {
            submitRequest(e.values);
          }}
          render={() => (
            <FormElement className="main-container">
              {!requestBody ||
              getRequestById.isLoading ||
              updateRequest.isLoading ? (
                <div className="requestForm--loading">
                  <Loader type="pulsing" size="large" />
                </div>
              ) : (
                <>
                  <div className="form-container">
                    <div className="group-container">
                      <div className="form-item">
                        <Label editorId={"id"}>ID</Label>
                        <Field name={"id"} component={IdInput} />
                      </div>
                      <div className="form-item">
                        <Label editorId={"currentStatus"}>
                          *{t("CurrentStatus")}
                        </Label>
                        <Field
                          name={"currentStatus"}
                          component={StatusComboBox}
                        />
                      </div>
                      {(futureStatus?.id === 2 ||
                        requestBody?.rejectionReason) && (
                        <div className="form-item">
                          <Label editorId={"rejectionReason"}>
                            *{t("RejectionReason")}
                          </Label>
                          <Field
                            name={"rejectionReason"}
                            component={RejectionReasonTextArea}
                          />
                        </div>
                      )}
                      <div className="form-item">
                        <Label editorId={"title"}>*{t("Title")}</Label>
                        <Field name={"title"} component={TitleInput} />
                      </div>
                      <div className="form-item">
                        <Label editorId={"description"}>
                          *{t("Description")}
                        </Label>
                        <Field
                          name={"description"}
                          component={DescriptionTextArea}
                        />
                      </div>
                      <div className="form-item">
                        <Label editorId={"requester"}>{t("Requester")}</Label>
                        <Field name={"requester"} component={RequesterInput} />
                        {requestBody?.requesterApplicationUser
                          ?.isOnVacation && (
                          <Hint>
                            {displayVacationInfo(
                              requestBody?.requesterApplicationUser
                            )}
                          </Hint>
                        )}
                      </div>
                      {requestBody?.department && (
                        <div className="form-item">
                          <Label editorId={"Department"}>
                            {t("Department")}
                          </Label>
                          <Field
                            name={"Department"}
                            component={DepartmentComboBox}
                          />
                        </div>
                      )}
                      {requestBody?.requestInTheNameOf && (
                        <div className="form-item">
                          <Label editorId={"requestInTheNameOf"}>
                            {t("RequestInTheNameOf")}
                          </Label>
                          <Field
                            name={"requestInTheNameOf"}
                            component={RequestInTheNameOfInput}
                          />
                        </div>
                      )}
                      <div className="form-item">
                        <Label editorId={"validator"}>*{t("Validator")}</Label>
                        <Field
                          name={"validator"}
                          component={ValidatorComboBox}
                        />
                        {requestBody?.validatorApplicationUser
                          ?.isOnVacation && (
                          <Hint>
                            {displayVacationInfo(
                              requestBody?.validatorApplicationUser
                            )}
                          </Hint>
                        )}
                      </div>
                      {requestBody?.budgetOwner && (
                        <div className="form-item">
                          <Label editorId={"budgetOwner"}>
                            {t("BudgetOwner")}
                          </Label>
                          <Field
                            name={"budgetOwner"}
                            component={BudgetOwnerInput}
                          />
                        </div>
                      )}
                      <div className="form-item">
                        <Label editorId={"TeSPOC"}>*{t("TeSpoc")}</Label>
                        <Field name={"TeSPOC"} component={TeSPOCComboBox} />
                        {requestBody?.teSpocApplicationUser?.isOnVacation && (
                          <Hint>
                            {displayVacationInfo(
                              requestBody?.teSpocApplicationUser
                            )}
                          </Hint>
                        )}
                      </div>
                      {requestBody?.setStatusDto?.currentStatus! >=
                        RequestStatus.Reviewing && (
                        <div className="form-item">
                          <Label editorId={"teExpert"}>*{t("TeExpert")}</Label>
                          <Field
                            name={"teExpert"}
                            component={TeExpertComboBox}
                          />
                          {requestBody?.teExpertApplicationUser
                            ?.isOnVacation && (
                            <Hint>
                              {displayVacationInfo(
                                requestBody?.teExpertApplicationUser
                              )}
                            </Hint>
                          )}
                        </div>
                      )}
                      {subSpoc && subSpoc?.isOnVacation && (
                        <div className="form-item">
                          <Label editorId={"SubSPOC"}>Subject SPOC</Label>
                          <Field name={"SubSPOC"} component={SubSpocInput} />
                          <Hint>{displayVacationInfo(subSpoc)}</Hint>
                        </div>
                      )}
                    </div>
                    <div className="group-container">
                      <div className="form-item">
                        <Label editorId={"subject"}>{t("Subject")}</Label>
                        <Field name={"subject"} component={SubjectInput} />
                      </div>
                      {requestBody.company?.isWDApplicable && (
                        <div className="form-item">
                          <Label editorId={"wdNumber"}>{t("WDNumber")}</Label>
                          <Field name={"wdNumber"} component={WdNumberInput} />
                        </div>
                      )}
                      <div className="form-item">
                        <Label editorId={"projectNumber"}>
                          {t("ProjectNumber")}
                        </Label>
                        <Field
                          name={"projectNumber"}
                          component={ProjectNumberInput}
                        />
                      </div>
                      <div className="form-item">
                        <Label editorId={"imputationNumber"}>
                          {t("ImputationNumber")}
                        </Label>
                        <Input
                          defaultValue={imputationNumber}
                          onChange={(e) => handleImputationNumberChange(e)}
                          disabled={!user.data?.tractebelEmployee}
                        />
                      </div>
                      <div className="toggle-item">
                        <Label editorId={"seismicQuestions"}>
                          {t("SeismicQuestions")}
                        </Label>
                        <Field
                          name={"seismicQuestions"}
                          component={SeismicQuestionsSwitch}
                        />
                      </div>
                      {requestBody?.reference && (
                        <div className="form-item">
                          <Label editorId={"typeOfReference"}>
                            {t("TypeOfReference")}
                          </Label>
                          <Field
                            name={"typeOfReference"}
                            component={ReferenceDropdownList}
                          />
                        </div>
                      )}
                      {requestBody?.referenceNumber && (
                        <div className="form-item">
                          <Field
                            name={"referenceNumber"}
                            component={ReferenceInput}
                          />
                        </div>
                      )}
                      {requestBody?.reference?.referenceCheckboxLabel && (
                        <div className="checkbox-item">
                          <Label editorId={"referenceCheckbox"}>
                            {requestBody?.reference?.referenceCheckboxLabel}
                          </Label>
                          <Field
                            name={"referenceCheckbox"}
                            component={ReferenceCheckbox}
                          />
                        </div>
                      )}
                      {requestBody?.setStatusDto?.currentStatus! >=
                        RequestStatus.Reviewing && (
                        <div className="form-item">
                          <Label editorId={"estimation"}>
                            *{t("Estimation")} ({t("InHours")})
                          </Label>
                          <NumericTextBox
                            placeholder={t("EnterValueInHours")}
                            defaultValue={estimation}
                            min={1}
                            disabled={
                              !(
                                isInEdit &&
                                (requestBody?.setStatusDto?.currentStatus ===
                                  RequestStatus.Reviewing ||
                                  requestBody?.setStatusDto?.currentStatus ===
                                    RequestStatus.RejectedByClient ||
                                  requestBody?.setStatusDto?.currentStatus ==
                                    RequestStatus.Revision)
                              )
                            }
                            onChange={(e) => {
                              handleEstimationChange(e);
                            }}
                          />
                          <div>
                            <Label editorId={"estimation"}>
                              {t("HourlyRate")}
                            </Label>
                            <NumericTextBox
                              value={cost}
                              readOnly={true}
                              format={euroFormat}
                              spinners={false}
                              disabled={true}
                            />
                            <Label editorId={"estimation"}>
                              {t("EstimationCost")}
                            </Label>
                            <NumericTextBox
                              value={cost! * estimation}
                              readOnly={true}
                              format={euroFormat}
                              spinners={false}
                              disabled={true}
                            />
                          </div>
                          <Label editorId={"proposedDeadline"}>
                            {t("ProposedDeadline")}
                          </Label>
                          <Field
                            name={"proposedDeadline"}
                            component={DeadlineEstimationDatePicker}
                          />
                          <Label editorId={"estmationComment"}>
                            {t("EstimationComment")}
                          </Label>
                          <Field
                            name={"estimationComment"}
                            component={EstimationComment}
                          />
                        </div>
                      )}
                    </div>
                    <div className="group-container">
                      <div className="form-item">
                        <Label editorId={"attachments"}>
                          {t("Attachments")}
                        </Label>
                        {attachmentsComponent}
                        {(requestBody.teExpertApplicationUser?.id ===
                          user.data?.id ||
                          user.data?.engieUserRole === Roles.Admin) &&
                          requestBody.setStatusDto?.currentStatus !== 2 &&
                          requestBody.setStatusDto?.currentStatus !== 7 &&
                          requestBody.setStatusDto?.currentStatus !== 8 &&
                          requestBody.setStatusDto?.currentStatus !== 9 &&
                          requestBody.setStatusDto?.currentStatus !== 10 && (
                            <Upload
                              multiple={true}
                              defaultFiles={[]}
                              autoUpload={true}
                              withCredentials={true}
                              saveUrl={`${process.env.REACT_APP_HOST}/api/upload-files`}
                              removeUrl={`${process.env.REACT_APP_HOST}/api/upload-files`}
                              onStatusChange={(event) =>
                                addAttachmentsHandler(event)
                              }
                              onRemove={(event) =>
                                removeAttachmentHandler(event)
                              }
                              restrictions={{
                                allowedExtensions: [
                                  ".pdf",
                                  ".docx",
                                  ".xslx",
                                  ".jpg",
                                  ".png",
                                  ".tiff",
                                  ".zip",
                                  ".xlsx",
                                  ".xlsm",
                                  ".pptm",
                                  ".docm",
                                ],
                                maxFileSize: 31457280, //30MB
                              }}
                            />
                          )}
                      </div>
                      <div className="form-item">
                        <Label editorId={"deadline"}>*{t("Deadline")}</Label>
                        <Field
                          name={"deadline"}
                          component={DeadlineDatePicker}
                        />
                      </div>
                      <div className="form-item">
                        <Label editorId={"priorityLevel"}>
                          *{t("PriorityLevel")}
                        </Label>
                        <Field
                          name={"priorityLevel"}
                          component={PriorityDropDownList}
                        />
                      </div>
                      <p>{t("ItIsRequiredToSelectMinimumOneAnswer")}</p>
                      {answersList.length > 1 &&
                        answersList.map((answer: any, index: number) => (
                          <div key={index}>
                            <div className="toggle-item">
                              <Label
                                editorId={`${answer.typeOfAnswerDictionaryId}`}
                              >
                                {t(answer.typeOfAnswerDictionaryName)}
                              </Label>
                              <Field
                                name={`${answer.typeOfAnswerDictionaryId}`}
                                component={AnswerSwitch}
                              />
                            </div>
                            {answer.typeOfAnswerDictionaryId === 5 &&
                              requestBody?.otherReason && (
                                <Field
                                  name={"otherReason"}
                                  component={(props) => (
                                    <TextArea
                                      defaultValue={requestBody?.otherReason}
                                      {...props}
                                    />
                                  )}
                                />
                              )}
                          </div>
                        ))}
                    </div>
                  </div>
                  <div>
                    <ChatComponent
                      comments={comments}
                      onPostComment={(data: string) => submitComment(data)}
                      onRemoveComment={(commentId: string) =>
                        removeComment(commentId)
                      }
                    />
                  </div>
                  <div className="button-container">
                    <Button type="button" onClick={() => navigate("/")}>
                      {t("Back")}
                    </Button>
                    {((user?.data?.isOnVacation &&
                      (user?.data?.id ===
                        requestBody.validatorApplicationUserId ||
                        user?.data?.id ===
                          requestBody.teExpertApplicationUserId ||
                        user?.data?.id ===
                          requestBody.teSpocApplicationUserId)) ||
                      user?.data?.engieUserRole === Roles.Admin) && (
                      <Button type="button" onClick={() => updateUser()}>
                        {t("ReassignRequest")}
                      </Button>
                    )}
                    {requestBody?.setStatusDto?.currentStatus !==
                      RequestStatus.AwaitingFeedback &&
                      requestBody?.setStatusDto?.currentStatus !==
                        RequestStatus.Revision &&
                      requestBody?.setStatusDto?.currentStatus !==
                        RequestStatus.RejectedByTractebel &&
                      requestBody?.setStatusDto?.currentStatus !==
                        RequestStatus.RejectedByClient &&
                      requestBody?.setStatusDto?.currentStatus !==
                        RequestStatus.Closed &&
                      isInEdit && (
                        <Button type={"submit"}>
                          {t("ChangeTo")}{" "}
                          {t(
                            RequestStatus[
                              requestBody?.setStatusDto?.currentStatus! + 1
                            ]
                          )}
                        </Button>
                      )}
                    {requestBody?.setStatusDto?.currentStatus ===
                      RequestStatus.InProgress &&
                      isInEdit && (
                        <Button type="button" onClick={toFinished}>
                          {t("ChangeTo")} {t(RequestStatus[6])}
                        </Button>
                      )}
                    {requestBody?.setStatusDto?.currentStatus ===
                      RequestStatus.AwaitingFeedback &&
                      isInEdit && (
                        <Button type="button" onClick={toInProgress}>
                          {t("ChangeTo")} {t(RequestStatus[4])}
                        </Button>
                      )}
                    {(requestBody?.setStatusDto?.currentStatus ===
                      RequestStatus.RejectedByClient ||
                      requestBody?.setStatusDto?.currentStatus ===
                        RequestStatus.Revision) &&
                      isInEdit && (
                        <Button type={"submit"}>
                          {t("ChangeTo")} {t(RequestStatus[2])}
                        </Button>
                      )}

                    {
                        requestBody?.setStatusDto?.currentStatus === RequestStatus.InProgress 
                      && 
                      isInEdit && (
                        <Button type="button" onClick={toRevision}>
                            {t("MoveToRevision")}
                        </Button>
                      )}
                    {(
                        requestBody?.setStatusDto?.currentStatus === RequestStatus.Reviewing ||
                        requestBody?.setStatusDto?.currentStatus === RequestStatus.Estimated ||                        
                        requestBody?.setStatusDto?.currentStatus === RequestStatus.Finished)
                        &&
                        isInEdit && (
                            <Button type="button" onClick={toggleDialog}>
                                {t("Reject")}
                            </Button>
                        )}
                    {requestBody?.setStatusDto?.currentStatus ===
                      RequestStatus.Reviewing &&
                      isInEdit && (
                        <Button
                          type="button"
                          onClick={() => setIsSubjectDialogVisible(true)}
                        >
                          {t("SelectNewSubject")}
                        </Button>
                      )}
                  </div>
                </>
              )}
            </FormElement>
          )}
        />
      </div>
    </React.Fragment>
  );
};

export default RequestForm;
